import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
// import Link from '@input-output-hk/front-end-core-components/components/Link'

import ImagesQuery from '../queries/ImagesQuery'
import Section from './Section'
import BgImage from './BgImage'
import Button from './Button'

import AppstoreSvg from '../../resources/images/svg/inline/appstore.svg'

const Content = styled.div`
  width: 80%;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  top: 70%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  padding-bottom: 10rem;
`

const Ewrapper = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  text-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);

  @media (min-width: 1400px) {
    flex-direction: row;
  }
`
const Ecenter = styled.div`
  color: white;
  margin: 0 10px;

  @keyframes fade-down-1 {
    0% {
      opacity: 0;
      transform: translateY(20px);
    }
    100% {
      opacity: 1;
      transform: translateY(0px);
    }
  }
  @keyframes fade-down-2 {
    0% {
      opacity: 0;
      transform: translateY(20px);
    }
    50% {
      opacity: 0;
      transform: translateY(20px);
    }
    100% {
      opacity: 1;
      transform: translateY(0px);
    }
  }

  &:nth-of-type(1) {
    animation: fade-down-1 1.2s ease-out;
  }

  &:nth-of-type(2) {
    animation: fade-down-2 2s ease-out;
  }
`
const Ebottom = styled.div`
  color: white;
  text-align: center;
  text-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);

  p {
    margin-top: 0;
    margin-bottom: 50px;
    font-weight: 500;
    font-style: italic;
    font-size: 22px;
  }
  animation-name: slide-in;
  animation-duration: 2.6s;

  @keyframes slide-in {
    0% {
      opacity: 0;
    }
    70% {
      opacity: 0;
      transform: translateX(-20px);
    }
    100% {
      opacity: 1;
      transform: translateX(0px);
    }
  }

  @media (min-width: 1200px) {
    p {
      margin-top: 0;
      margin-bottom: 50px;
      font-weight: 500;
      font-style: italic;
      font-size: 36px;
    }
  }
`
const RelativeSection = styled(Section)`
  position: relative;
`
const Shadow = styled.div`
  &::after {
    content: '';
    position: absolute;
    opacity: 0.65;
    width: 100%;
    height: 100%;
    z-index: 0;

    top: 0;
    right: 0;
    left: 0;
    bottom: 0;

    background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 1) 100%
    );
  }
`

const StyledAppstoreSvg = styled(AppstoreSvg)`
  margin-top: 28px;
  width: 280px;
`

// const DOWNLOAD_URL = 'http://onelink.to/k9rjj4'

const HERO_IMAGES = [ 'heroSix' ]
const Hero = ({ ecenter1, ecenter2, ebottom, link, onBtnClickHandler }) => {
  const [ imgIdx, setImgIdx ] = useState(0)

  useEffect(() => {
    const interval = setInterval(() => {
      setImgIdx((imgIdx) => (imgIdx + 1) % HERO_IMAGES.length)
    }, 3000)
    return () => clearInterval(interval)
  }, [])

  const getImgFluid = (images) =>
    images[HERO_IMAGES[imgIdx]].childImageSharp.fluid

  return (
    <ImagesQuery
      render={(images) => (
        <RelativeSection>
          <BgImage crossfade title='hero' fluid={getImgFluid(images)} height='100vh'>
            <Shadow>
              <Content>
                <Ewrapper>
                  <Ecenter>{ecenter1}</Ecenter>
                  {/* <Ecenter>{ecenter2}</Ecenter> */}
                </Ewrapper>
                <Ebottom>{ebottom}</Ebottom>
                {/* <Link tracking={{ label: 'hero_download' }} href={DOWNLOAD_URL}> */}
                <Button variant='large' onClick={onBtnClickHandler}>{link}</Button>
                <StyledAppstoreSvg />
                {/* </Link> */}
              </Content>
            </Shadow>
          </BgImage>
        </RelativeSection>
      )}
    />
  )
}

Hero.propTypes = {
  ecenter1: PropTypes.node,
  ecenter2: PropTypes.node,
  ebottom: PropTypes.node,
  link: PropTypes.string,
  onBtnClickHandler: PropTypes.func
}

export default Hero
